<template>
  <div class="container">
    <main>
      <div class="py-5 text-center">
        <img
          class="d-block mx-auto mb-4"
          src="../../assets/img-minhastar/starAmigoAzul.png"
          alt=""
          height="70"
        />
        <h2>Star Amigo</h2>
        <p class="lead mb-0">
          Bônus indique um amigo e ganhe 50% de desconto em uma mensalidade a
          cada indicação.
        </p>
        <p class="text-sm">
          Confira o regulamento clicando
          <a
            href="/arquivos/REGULAMENTO.pdf"
            target="_blank"
            class="link-regulamento"
            >aqui.</a
          >
        </p>
      </div>

      <div class="row g-5">
        <div class="col-md-12 col-lg-12">
          <h4 class="mb-3">Dados do Titular</h4>
          <div class="row g-3">
            <div class="col-12">
              <label for="nomeTitular" class="form-label">Nome Completo</label>
              <input
                class="form-control text-body text-bold"
                id="nomeTitular"
                placeholder="Nome completo..."
                autocomplete="off"
                v-model="NomeTitular"
              />
            </div>

            <div class="col-12">
              <label for="CpfCnpj" class="form-label">CPF/CNPJ</label>
              <input
                class="form-control text-body text-bold"
                id="CpfCnpj"
                autocomplete="off"
                v-maska="['###.###.###-##', '##.###.###/####-##']"
                placeholder="000.000.000-00"
                v-model="CpfTitular"
              />
            </div>

            <div class="col-12">
              <label for="telefone" class="form-label">Telefone</label>
              <input
                class="form-control text-body text-bold"
                id="telefone"
                v-maska="['(##) #####-####', '(##) ####-####']"
                placeholder="(00) 00000-0000"
                autocomplete="off"
                v-model="TelefoneTitular"
              />
            </div>
          </div>

          <hr class="my-4" />

          <h4 class="mb-3">Dados do Indicado</h4>

          <div class="row gy-3">
            <div class="col-12">
              <label for="NomeIndicado" class="form-label">Nome Completo</label>
              <input
                type="text"
                class="form-control text-body text-bold"
                id="NomeIndicado"
                autocomplete="off"
                placeholder="Nome completo..."
                v-model="NomeIndicado"
              />
            </div>

            <div class="col-12">
              <label for="TelefoneIndicado" class="form-label">Telefone</label>
              <input
                class="form-control text-body text-bold"
                v-maska="['(##) #####-####', '(##) ####-####']"
                autocomplete="off"
                id="TelefoneIndicado"
                placeholder="(00) 00000-0000"
                v-model="TelefoneIndicado"
              />
            </div>
          </div>

          <hr class="my-4" />

          <button
            class="w-100 btn bg-azulstar btn-lg"
            @click="EnviarDados"
          >
            Enviar
          </button>
        </div>
      </div>
    </main>

    <footer class="pb-3 pt-5 text-body-secondary text-center text-small">
      <p class="mb-1">&copy; 2024 STAR Internet e Telefonia</p>
    </footer>
  </div>
</template>

<script>
import { api } from "../../boot/axios";

export default {
  name: "BonusStarAmigo",
  data() {
    return {
      NomeTitular: undefined,
      CpfTitular: undefined,
      TelefoneTitular: undefined,
      NomeIndicado: undefined,
      TelefoneIndicado: undefined,
    };
  },
  methods: {
    EnviarDados() {
      if (
        !this.NomeTitular ||
        !this.CpfTitular ||
        !this.TelefoneTitular ||
        !this.NomeIndicado ||
        !this.TelefoneIndicado
      ) {
        this.$notify({
          type: "error",
          text: "Informações faltando! Tente novamente.",
          duration: 5000,
        });
      } else if (this.NomeTitular.length < 3) {
        this.$notify({
          type: "error",
          text: "O nome do titular está incompleto! Tente novamente.",
          duration: 5000,
        });
      } else if (this.NomeIndicado.length < 3) {
        this.$notify({
          type: "error",
          text: "O nome do titular está incompleto! Tente novamente.",
          duration: 5000,
        });
      } else if (this.CpfTitular.length != 14 && this.CpfTitular.length != 18) {
        this.$notify({
          type: "error",
          text: "Cpf/Cnpj do titular está incorreto! Tente novamente.",
          duration: 4000,
        });
      } else if (this.TelefoneTitular.length != 15) {
        this.$notify({
          type: "error",
          text: "Telefone do titular está incorreto! Tente novamente.",
          duration: 4000,
        });
      } else if (this.TelefoneIndicado.length != 15) {
        this.$notify({
          type: "error",
          text: "Telefone do indicado está incorreto! Tente novamente.",
          duration: 4000,
        });
      } else {
        if (this.validarCPF(this.CpfTitular)) {
          api
            .post("https://apiv2.minhastar.com.br/cadastros/indique", {
              NomeTitular: this.NomeTitular,
              CpfTitular: this.CpfTitular,
              TelefoneTitular: this.TelefoneTitular,
              NomeIndicado: this.NomeIndicado,
              TelefoneIndicado: this.TelefoneIndicado,
            })
            .then((response) => {
              if (response.data.erro != undefined) {
                this.$notify({
                  type: "error",
                  text:
                    "Algo deu errado em sua indicação! Tente novamente mais tarde!",
                  duration: 4000,
                });
              } else {
                this.$notify({
                  type: "success",
                  text: "Indicação cadastrada!",
                  duration: 6000,
                });
                (this.NomeTitular = undefined), (this.CpfTitular = undefined);
                this.TelefoneTitular = undefined;
                this.NomeIndicado = undefined;
                this.TelefoneIndicado = undefined;
              }
            })
            .catch(function (error) {
              console.error(error.response);
            });
        } else {
          this.$notify({
            type: "errro",
            text: "Informe um CPF válido!",
            duration: 4000,
          });
        }
      }
    },
    validarCPF(inputCPF) {
      inputCPF = inputCPF
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace("-", "");
      var soma = 0;
      var i;
      var resto;

      if (inputCPF == "00000000000") return false;
      for (i = 1; i <= 9; i++)
        soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(inputCPF.substring(9, 10))) return false;

      soma = 0;
      for (i = 1; i <= 10; i++)
        soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(inputCPF.substring(10, 11))) return false;
      return true;
    },
  },
};
</script>

<style>
* {
  color: #212529 !important;
}

.bg-azulstar,
.bg-azulstar:hover {
  background-color: #04274c;
  color: #fff !important;
}

.link-regulamento {
  color: #04274c;
  text-decoration: underline !important;
}

.link-regulamento:hover {
  text-decoration: none !important;
}
</style>
